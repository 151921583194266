<template lang="html">
  <div class="stones-form__step-content">
    <FormPaymentMethod
      v-if="organState.form.value"
      :donateValue="Number(price)"
      @chooseMethod="nextStep"
      @comeBack="prevStep"
    />
  </div>
</template>

<script>
import FormPaymentMethod from '@/components/Form/FormPaymentMethod.vue';

export default {
  name: 'OrganFormStep2',
  components: {
    FormPaymentMethod,
  },
  computed: {
    organState() {
      return this.$store.state.organ;
    },
    price() {
      return this.$store.getters['organ/currentPipeVariants'].prices[
        this.$store.state.organ.selectedPipe - 1
      ];
    },
  },
  methods: {
    nextStep() {
      const params = {
        pipe_id:
          this.$store.getters['organ/currentPipeVariants'].pipes[
            this.$store.state.organ.selectedPipe - 1
          ].value,
        paid_price: this.price,
        candle: {
          amount: this.price,
        },
        // price: this.price,
        // area: this.$store.getters['organ/currentPipeVariants'].areas[
        //   this.$store.state.organ.selectedPipe - 1
        // ].label,
        // pipe: this.$store.getters['organ/currentPipeVariants'].pipes[
        //   this.$store.state.organ.selectedPipe - 1
        // ].label,
        postType: 'organ',
      };
      this.$store.dispatch('donationPayment/pay', params);

      this.$store.commit('organ/setFormCurrentStep', 3);
    },
    prevStep() {
      this.$store.commit('organ/setFormCurrentStep', 1);
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'styles';
</style>
